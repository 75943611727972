import React, { Component } from "react";
import { Cell, Column, Table } from "@blueprintjs/table";
import "@blueprintjs/table/lib/css/table.css";

class MyTable extends React.Component {
  render() {
    return (
      <Table numRows={2}>
        <Column name="Event" />
        <Column name="Genre" />
        <Column name="Location" />
        <Column name="Time" />
        <Column name="Ticket Price" />
        <Column name="Contact" />
        <Column name="Age Group" />
      </Table>
    );
  }
}

export default MyTable;
