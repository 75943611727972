import React, { Component } from "react";
import ReactDOM from "react-dom";
import firebase, { auth, db } from "./firebase";
import CoverImage from "./CoverImage";
import Form from "./Form";
import { Button } from "@blueprintjs/core";
import MyTable from "./Tables";
import Navigation from "./Nav";
import "./styles.css";

class App extends Component {
  state = {
    user: null,
    doc: null
  };
  componentDidMount() {
    auth.onAuthStateChanged(user => {
      this.setState({ user });
    });

    // this.unsubFromDoc = db
    //   .collection("users")
    //   .doc(this.state.user.uid)
    //   .collection("posts")
    //   .doc("Cu9JZvUee1Ff7Z972MYj")
    //   .onSnapshot(doc => {
    //     console.log("Current data: ", doc.data());
    //     this.setState({ doc: doc.data() });
    //   });
  }
  componentWillUnmount() {
    this.unsubFromDoc();
  }
  login = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  testSaveData = () => {
    //if (!this.state.user) return;
    db.collection("users")
      .doc("NpkQPJMAXdOIzp8LcPB3U41DD052")
      .collection("posts")
      .add({
        name: "Los Angeles",
        // state: "CA",
        // country: "USAAAAA"
        title: "something"
      })
      .then(function() {
        console.log("Document successfully written!");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  };
  logout = () => auth.signOut();
  render() {
    const { user, doc } = this.state;
    return (
      <div className="App">
      <Navigation />

        <CoverImage />
        <div className="Form">
          <Form />
        </div>

        <MyTable />

        <CoverImage />
        {!user ? (
          <button onClick={this.login}>Login</button>
        ) : (
          <button onClick={this.logout}>Logout</button>
        )}
       <hr />
        <button onClick={this.testSaveData}>Save data</button>
        <hr />
        <h1>Logged in User:</h1>
        <div>{JSON.stringify(user)}</div>
        <hr />
        <h1>Doc:</h1>
        <div>{JSON.stringify(doc)}</div>
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
