import React, { Component } from "react";
import { storage } from "./firebase";

class CoverImage extends Component {
  constructor(props) {
    super(props);
    this.domFileInput = React.createRef(); // this.domFileInput.current
  }
  state = {
    coverImage: null
  };
  componentDidMount() {
    this.getCoverImage();
  }
  getCoverImage = () => {
    storage
      .ref()
      .child("general")
      .child("cover")
      .getDownloadURL()
      .then(url => {
        console.log(url);
        this.setState({ coverImage: url });
      });
  };
  onFileSelect = e => {
    console.log(this.domFileInput.current.files);
    const file = this.domFileInput.current.files[0];
    const uploadTask = storage
      .ref()
      .child("general")
      .child("cover")
      .put(file);
    // .then(s => console.log("File uploaded"))
    // .catch(err => console.log("Error", err));

    uploadTask.on(
      "state_changed",
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      function(error) {
        console.log("failed", error);
      },
      function() {
        console.log("DONE UPLOADING");
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        //   console.log('File available at', downloadURL);
        // });
      }
    );
  };

  render() {
    return (
      <div className="CoverImage">
        <input
          type="file"
          onChange={this.onFileSelect}
          ref={this.domFileInput}
        />
      </div>
    );
  }
}

export default CoverImage;
