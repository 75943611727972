import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDGrO9nvZLV0XySB8ywjsv25kzqQ5EBDAo",
  authDomain: "goalwise-b76a1.firebaseapp.com",
  databaseURL: "https://goalwise-b76a1.firebaseio.com",
  projectId: "goalwise-b76a1",
  storageBucket: "goalwise-b76a1.appspot.com",
  messagingSenderId: "758113869319",
  appId: "1:758113869319:web:f3f075c1e20bcbf22794a2",
  measurementId: "G-5EYNH1MB3S"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

export default firebase;
