import React from "react";
import { Button } from "@blueprintjs/core";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
//const Button = props => <button>{props.children || "Press me"}</button>;

class Form extends React.Component {
  state = {
    email: ""
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.updEmail(this.state.email);
  };
  upd = e => this.setState({ [e.target.name]: e.target.value });
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>{this.props.title}</h1>
        <input
          onChange={this.upd}
          name="email"
          value={this.state.email}
        /> - {this.state.email}
        <Button>Click me</Button>
      </form>
    );
  }
}

export default Form;
