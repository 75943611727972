import React, { Component } from "react";
import {
  Alignment,
  AnchorButton,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider
} from "@blueprintjs/core";

class MyNav extends React.Component {
  render() {
    return (
      <Navbar className={Classes.DARK}>
        <NavbarGroup align={Alignment.RIGHT}>
          <NavbarHeading>Welcome Friends</NavbarHeading>
          <NavbarDivider />
          <AnchorButton
            href="http://blueprintjs.com/docs/v2/"
            text="About"
            target="_blank"
            minimal
            rightIcon="arrow-top-right"
          />
          <AnchorButton
            href="http://karengtrz.com"
            text="Home"
            target="_blank"
            minimal
            rightIcon="home"
          />
        </NavbarGroup>
      </Navbar>
    );
  }
}

export default MyNav;
